import React, { useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useAuth } from "../components/context/auth"
import { CubeTransparentIcon } from "@heroicons/react/outline"
import axios from "axios"
import uniqueSlug from "unique-slug"
import store from "store"
import { useMutation } from "react-query"
import * as api from "../components/context/api"
import { not } from "joi"

const Register = () => {
  const { signup, loginWithGoogle, currentUser } = useAuth()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const mobileRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  // if (currentUser) {
  //   navigate("/app/home")
  // }

  const mutation = useMutation(() => api.postStudent, {
    onSuccess: async data => {
      console.log("success sign up", data)
      await signup(emailRef.current.value, passwordRef.current.value)
      store.set("user", { email: emailRef.current.value })
      navigate("/app/home", { replace: true })
    },
    onError: () => console.log("Strapi Error on students post"),
  })

  const postStudentDataStrapi = Studentdata => {
    axios
      .post(`${process.env.GATSBY_API_URL}/students`, Studentdata, {
        headers: {
          Authorization: `Bearer ${store.get("stoken")}`,
        },
      })
      .then(res => {
        console.log("Success")
      })
      .catch(err => {
        setError("Error signing up")
        setLoading(false)
        console.log("failed to post data", err)
      })
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match")
    }

    try {
      setError("")
      setLoading(true)
      const StudentData = {
        email: emailRef.current.value,
        mobileNo: mobileRef.current.value,
        password: passwordRef.current.value,
        studentId: uniqueSlug(emailRef.current.value),
      }
      console.log(StudentData)
      // await postStudentDataStrapi(StudentData)
      mutation.mutate(StudentData)
      // await signup(emailRef.current.value, passwordRef.current.value)
      // navigate("/app/home", { replace: true })
    } catch (err) {
      setError("Failed to create an account")
      setError(err.message)
      setLoading(false)
    }
  }

  const googleAuthHandler = async e => {
    try {
      await loginWithGoogle()
      navigate("/app/home", { replace: true })
    } catch (err) {
      setError("Failed to create an account")
    }
  }

  return (
    <div className="flex items-center justify-center w-full h-screen mx-auto ">
      <div className="w-full max-w-sm p-6 m-auto bg-white shadow rounded-xl dark:bg-gray-800">
        <h1 className="text-3xl font-semibold text-center text-skin-inverted dark:text-white">
          <Link to={`/`}>acadmi</Link>
        </h1>
        <p className="text-xl text-center">Create your Free Account</p>
        <div className="flex items-center mt-6 ">
          <button
            type="button"
            onClick={googleAuthHandler}
            className="flex items-center justify-center w-full px-6 py-2 text-sm font-medium transition-colors duration-200 transform bg-gray-100 border rounded-md hover:bg-gray-200 focus:outline-none"
          >
            <StaticImage
              src="../images/google.png"
              alt="google logo"
              className="w-4 h-4"
              placeholder={"tracedSVG"}
            />

            <span className="mx-2">Sign up with Google</span>
          </button>
        </div>
        <div className="flex items-center justify-center m-2 text-gray-400">
          or
        </div>
        <form className="mt-6 " onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="username"
              className="block text-sm text-gray-800 dark:text-gray-200"
            >
              Email
            </label>
            <input
              type="text"
              ref={emailRef}
              required
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
          </div>

          <div className="mt-4">
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm text-gray-800 dark:text-gray-200"
              >
                Create a password
              </label>
            </div>

            <input
              type="password"
              ref={passwordRef}
              required
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <label
                htmlFor="passwordConfirm"
                className="block text-sm text-gray-800 dark:text-gray-200"
              >
                Confirm password
              </label>
            </div>

            <input
              type="password"
              ref={passwordConfirmRef}
              required
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
          </div>
          <div className="mt-4">
            <div className="flex items-center justify-between">
              <label
                htmlFor="mobile"
                className="block text-sm text-gray-800 dark:text-gray-200"
              >
                Mobile
              </label>
            </div>

            <input
              type="text"
              ref={mobileRef}
              required
              maxLength={10}
              minLength={10}
              className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
          </div>
          {error && <p className="mt-5 text-red-400">{error}</p>}
          <div className="mt-6">
            <button
              disabled={loading}
              className="w-full py-5 btn disabled:opacity-70"
            >
              <div className="flex justify-center text-center">
                <CubeTransparentIcon
                  className={`${
                    loading ? "animate-spin w-5 h-5 mr-2" : "hidden"
                  }`}
                />{" "}
                Sign up
              </div>
            </button>
          </div>
        </form>

        <p className="mt-8 text-xs font-light text-center text-gray-400">
          Already have an account?
          <Link
            to={`/login`}
            href="#"
            className="ml-2 font-medium text-gray-800 dark:text-gray-200 hover:underline"
          >
            Login
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Register
